/***************************************** Theme *****************************************/
@import './core/theme/ui-variables/ui-variables.scss';

/***************************************** Fonts *****************************************/
@import './core/theme/fonts/_fonts.scss';

@import '~react-image-gallery/styles/scss/image-gallery.scss';

// Custom selection
::selection {
  background: hsla(
    var(--primary-hue),
    calc(var(--primary-saturation) * 1%),
    calc(var(--primary-luminosity) * 1%),
    1
  );
  color: hsla(
    var(--primaryContrast-hue),
    calc(var(--primaryContrast-saturation) * 1%),
    calc(var(--primaryContrast-luminosity) * 1%),
    1
  );
}

// Custom scrollbar for  -webkit- browsers
::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  background: hsla(
    var(--textLight-hue),
    calc(var(--textLight-saturation) * 1%),
    calc(var(--textLight-luminosity) * 1%),
    0
  );

  &-thumb {
    background: hsla(
      var(--textLight-hue),
      calc(var(--textLight-saturation) * 1%),
      calc(var(--textLight-luminosity) * 1%),
      1
    );
  }
}

// Custom scrollbar for Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: hsla(
      var(--textLight-hue),
      calc(var(--textLight-saturation) * 1%),
      calc(var(--textLight-luminosity) * 1%),
      1
    )
    hsla(
      var(--textLight-hue),
      calc(var(--textLight-saturation) * 1%),
      calc(var(--textLight-luminosity) * 1%),
      0
    );
}

/***************************************** Global reset *****************************************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: var(--main-font), 'Roboto', sans-serif !important;
}

html {
  font-size: var(--html-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
  color: hsla(
    var(--text-hue),
    calc(var(--text-saturation) * 1%),
    calc(var(--text-luminosity) * 1%),
    1
  );
  overflow: hidden;
}

body {
  font-size: 1.6rem;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

button {
  border: none;
  cursor: pointer;
}

button:focus,
textarea:focus,
input:focus {
  outline: none;
}

/***************************************** Styles *****************************************/
#root {
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 2.4rem;
  font-weight: 900;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
}

h3 {
  font-size: 1.8rem;
  font-weight: 700;
}

h4 {
  font-size: 1.6rem;
  font-weight: 700;
}

h5 {
  font-size: 1.4rem;
  font-weight: 800;
}

h6 {
  font-size: 1.2rem;
  font-weight: 700;
}

p {
  &.big {
    font-size: 1.8rem;
  }

  &.small {
    font-size: 1.4rem;
  }

  &.x-small {
    font-size: 1.2rem;
  }
}

//Removes blue border from map when out of focus
.gm-style > div[style] {
  border: none !important;
}

.amenityMarkerTooltip {
  position: absolute;
  top: 50%;
  left: calc(100% + 2rem);
  transform: translateY(-50%);
  background: var(--primary);
  color: var(--primaryContrast) !important;
  padding: 0.5rem 1.5rem;
  border-radius: 0.8rem;
  pointer-events: none;
}
