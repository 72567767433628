@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'OverPass';
  src: url('../../../assets/fonts/Overpass-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

// -------------------------------------------------------------------------------------------- //
:root {
  --main-font: 'OverPass';
}
