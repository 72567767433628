/*****************************************  UI Variables  *****************************************/
:root {
  --sidebarWidth: 38.5rem;
  --sidebarPadding: 3rem;
  --sidebarHeaderHeight: 9rem;
  --sidebarFooterHeight: 9rem;

  // Location Modal
  --locationDialogWidth: 38rem;
  --locationSpacing: 2.4rem;
  --locationProfilePictureSize: 6.4rem;
  --locationHeaderHeight: 6.6rem;
  --locationCoverPhotoWidth: 17.5rem;

  --drivingStyleFilterSize: 3.2rem;

  --borderRadius: 1.8rem;
  //Global font-size
  --html-font-size: 10px;

  @media screen and (max-width: 550px) {
    --sidebarWidth: 100%;
    --sidebarHeaderHeight: 6rem;
  }
}
